<template>
  <div>
    <div class="title">
      <div class="warp">
        <p>智能指纹柜锁 Y02B</p>
        <el-radio-group v-model="radio" @input="getValue">
          <el-radio label="1">详情</el-radio>
          <el-radio label="2">参数</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="container" v-show="show">
      <div class="content hidden-md-and-down">
        <section class="box1">
          <img
            style="width: 100%; min-width: 1200px"
            src="../../assets/mock/Y02B/1备份 2.jpg"
            alt=""
          />
        </section>
        <section ref="box2" class="box2">
          <div style="height: 164px; overflow: hidden">
            <h3 class="find_down" :class="box2 ? 'find_up' : ''">
              宇起智能柜锁，智能生活的小帮手
            </h3>
          </div>
          <ul>
            <li>
              <img src="../../assets/mock/Y02B/2/小图1.png" alt="" />
              <div>
                <p
                  class="find_down"
                  :class="box2 ? 'find_up' : ''"
                  style="transition: all 1s; transition-delay: 0.1s"
                >
                  传统钥匙锁长期悬挂不用
                </p>
                <p
                  class="find_down"
                  :class="box2 ? 'find_up' : ''"
                  style="transition: all 1s; transition-delay: 0.2s"
                >
                  钥匙不易管理
                </p>
                <span
                  class="find_down"
                  :class="box2 ? 'find_up' : ''"
                  style="transition: all 1s; transition-delay: 0.3s"
                ></span>
              </div>
            </li>
            <li>
              <div>
                <p
                  class="find_down"
                  :class="box21 ? 'find_up' : ''"
                  style="transition: all 1s; transition-delay: 0.1s"
                >
                  宝宝翻箱倒柜
                </p>
                <p
                  class="find_down"
                  :class="box21 ? 'find_up' : ''"
                  style="transition: all 1s; transition-delay: 0.2s"
                >
                  乱拿东西
                </p>
                <p
                  class="find_down"
                  :class="box21 ? 'find_up' : ''"
                  style="transition: all 1s; transition-delay: 0.3s"
                >
                  不够安全
                </p>
                <span
                  class="find_down"
                  :class="box21 ? 'find_up' : ''"
                  style="transition: all 1s; transition-delay: 0.4s"
                ></span>
              </div>
              <img src="../../assets/mock/Y02B/2/小图2.png" alt="" />
            </li>
            <li>
              <img src="../../assets/mock/Y02B/2/小图3.png" alt="" />
              <div>
                <p
                  class="find_down"
                  :class="box22 ? 'find_up' : ''"
                  style="transition: all 1s; transition-delay: 0.1s"
                >
                  存放一些贵重物品
                </p>
                <p
                  class="find_down"
                  :class="box22 ? 'find_up' : ''"
                  style="transition: all 1s; transition-delay: 0.2s"
                >
                  如证件、首饰等
                </p>
                <span
                  class="find_down"
                  :class="box22 ? 'find_up' : ''"
                  style="transition: all 1s; transition-delay: 0.3s"
                ></span>
              </div>
            </li>
            <li>
              <div>
                <p
                  class="find_down"
                  :class="box23 ? 'find_up' : ''"
                  style="transition: all 1s; transition-delay: 0.1s"
                >
                  宇起智能柜锁
                </p>
                <p
                  class="find_down"
                  :class="box23 ? 'find_up' : ''"
                  style="transition: all 1s; transition-delay: 0.2s"
                >
                  帮你解决以上所有难题
                </p>
                <span
                  class="find_down"
                  :class="box23 ? 'find_up' : ''"
                  style="transition: all 1s; transition-delay: 0.3s"
                ></span>
              </div>
              <img src="../../assets/mock/Y02B/2/小图4.png" alt="" />
            </li>
          </ul>
        </section>
        <section class="box3" ref="box3">
          <h3 class="find_down" :class="box3 ? 'find_up' : ''">
            授权家人共同使用
          </h3>
          <div
            style="transition: all 1s"
            class="find_down"
            :class="box31 ? 'find_up' : ''"
          >
            <p>点击授权</p>
            <p style="margin: 70px 0 0 130px">授权家人</p>
          </div>
        </section>
        <section class="box4" ref="box4">
          <img
            style="display: block"
            width="100%"
            src="../../assets/mock/Y02B/4备份.jpg"
            alt=""
          />
          <img
            style="display: block"
            width="100%"
            src="../../assets/mock/Y02B/5.jpg"
            alt=""
          />
          <p
            class="find_down"
            style="transition: all 1s"
            :class="box4 ? 'find_up' : ''"
          >
            <img src="../../assets/mock/Y02B/duoren.png" alt="" />
          </p>
        </section>
        <section class="box5" ref="box5">
          <h3 class="find_down" :class="box5 ? 'find_up' : ''">
            直插式快速安装
          </h3>
          <p
            style="-webkit-transition-delay: 0.5s; transition: all 0.5s"
            class="find_down"
            :class="box5 ? 'find_up' : ''"
          >
            设备采用直插式安装设计，方便快速替换原有机械锁，轻轻松松，传统柜秒变智能柜。
          </p>
        </section>
        <section class="box6" ref="box6">
          <h3 class="find_down" :class="box6 ? 'find_up' : ''">
            指纹头扁平设计，视觉上既美观，又彰显科技感
          </h3>
        </section>
        <section class="box7" ref="box7">
          <h3 class="find_down" :class="box7 ? 'find_up' : ''">支持多种柜型</h3>
          <img
            style="margin: 0 auto"
            src="../../assets/mock/Y02B/编组 5.png"
            alt=""
          />
        </section>
        <section class="box8" ref="box8">
          <h3 class="find_down" :class="box8 ? 'find_up' : ''">
            宇起自研识别算法
          </h3>
          <p
            style="transition: all 0.5s; transition-delay: 0.5s"
            class="find_down"
            :class="box8 ? 'find_up' : ''"
          >
            指纹算法拥有自学习能力，指纹开锁越用越灵敏。
          </p>
          <div>
            <p class="p1 find_down" :class="box81 ? 'find_up' : ''">
              <img src="../../assets/mock/Y02B/识别速度.png" alt="" />
            </p>
            <p class="p2 find_down" :class="box81 ? 'find_up' : ''">
              <img src="../../assets/mock/Y02B/指纹容量.png" alt="" />
            </p>
            <p class="p3 find_down" :class="box81 ? 'find_up' : ''">
              <img src="../../assets/mock/Y02B/全面识别.png" alt="" />
            </p>
          </div>
        </section>
        <section class="box9" ref="box9">
          <div>
            <div>
              <p class="p1 find_down" :class="box9 ? 'find_up' : ''">
                <img src="../../assets/mock/Y02B/高性能.png" alt="" />
              </p>
              <p class="p2 find_down" :class="box9 ? 'find_up' : ''">
                <img src="../../assets/mock/Y02B/高集成.png" alt="" />
              </p>
              <p class="p3 find_down" :class="box9 ? 'find_up' : ''">
                <img src="../../assets/mock/Y02B/安全可靠.png" alt="" />
              </p>
            </div>
          </div>
        </section>
        <section class="box10" ref="box10">
          <h3 class="find_down" :class="box10 ? 'find_up' : ''">
            低功耗设备 续航无忧
          </h3>
          <p
            style="transition: all 0.5s; transition-delay: 0.5s"
            class="find_down"
            :class="box10 ? 'find_up' : ''"
          >
            供电采用2节5号电池，使用时间更长，更换方便。
          </p>
          <div class="warp">
            <img
              width="706px"
              src="../../assets/mock/Y02B/位图备份.png"
              alt=""
            />
            <ul>
              <li
                class="find_down"
                :class="box10 ? 'find_up' : ''"
                style="transition: all 1s"
              >
                <img src="../../assets/mock/Y02B/1.png" alt="" />
                <span>低电量提醒</span>
              </li>
              <li
                class="find_down"
                :class="box10 ? 'find_up' : ''"
                style="transition: all 1s; transition-delay: 0.1s"
              >
                <img src="../../assets/mock/Y02B/2.png" alt="" /><span
                  >2节5号电池</span
                >
              </li>
              <li
                class="find_down"
                :class="box10 ? 'find_up' : ''"
                style="transition: all 1s; transition-delay: 0.2s"
              >
                <img src="../../assets/mock/Y02B/3.png" alt="" /><span
                  >3年超长待机</span
                >
              </li>
              <li
                class="find_down"
                :class="box10 ? 'find_up' : ''"
                style="transition: all 1s; transition-delay: 0.3s"
              >
                <img src="../../assets/mock/Y02B/4.png" alt="" /><span
                  >低维护成本</span
                >
              </li>
            </ul>
          </div>
        </section>
        <section class="box11" ref="box11">
          <h3 class="find_down" :class="box11 ? 'find_up' : ''">
            配备专用应急供电插头
          </h3>
          <p
            style="transition: all 0.5s; transition-delay: 0.5s"
            class="find_down"
            :class="box11 ? 'find_up' : ''"
          >
            即使遇到锁没电也不用担心开不了锁，我们可以使用日常Type-
            C充电线插上宇起应急供电插头开锁。
          </p>
        </section>
        <section
          class="box12"
          style="width: 100%; overflow: hidden"
          ref="box12"
        >
          <h3 class="find_down" :class="box12 ? 'find_up' : ''">
            宇起APP，全方位一站式管理
          </h3>
          <div>
            <ul>
              <li
                class="find_down"
                :class="box121 ? 'find_up' : ''"
                style="transition: all 1s; transition-delay: 0.1s"
              >
                <p>用户管理</p>
                <span>添加用户、授权用户，多种权限设置</span>
              </li>
              <li
                class="find_down"
                :class="box121 ? 'find_up' : ''"
                style="transition: all 1s; transition-delay: 0.2s"
              >
                <p>物品管理</p>
                <span>快速添加、快速查找、过期提醒</span>
              </li>
              <li
                class="find_down"
                :class="box121 ? 'find_up' : ''"
                style="transition: all 1s; transition-delay: 0.3s"
              >
                <p>多人开锁</p>
                <span>用于安全要求高的开锁场景</span>
              </li>
            </ul>
            <img
              :class="box121 ? '' : 'img_up'"
              src="../../assets/mock/Y02B/13备份.jpg"
              alt=""
            />
          </div>
        </section>
        <section class="box13">
          <div>
            <img
              style="margin: 0 auto; width: 100%"
              src="../../assets/mock/Y02B/14备份.jpg"
              alt=""
            />
          </div>
        </section>
      </div>
    </div>
    <div style="min-height: 80vh" v-show="show" class="hidden-lg-and-up">
      <img
        style="width: 100%; min-height: 80vh"
        src="../../assets/mock/shop/Y02B响应式详情.jpg"
        alt=""
      />
    </div>
    <div v-show="!show">
      <img
        style="margin: 0 auto; width: 100%"
        src="../../assets/mock/Y02B/14备份.jpg"
        alt=""
      />
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      radio: "1",
      show: true,
      currentScroll: 0,
      box2: false,
      box21: false,
      box22: false,
      box23: false,
      box3: false,
      box31: false,
      box4: false,
      box5: false,
      box6: false,
      box7: false,
      box8: false,
      box81: false,
      box9: false,
      box10: false,
      box11: false,
      box12: false,
      box121: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    getValue(value) {
      if (value == 1) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
    handleScroll() {
      const box2Top = this.$refs.box2.getBoundingClientRect().top;
      const box3Top = this.$refs.box3.getBoundingClientRect().top;
      const box4Top = this.$refs.box4.getBoundingClientRect().top;
      const box5Top = this.$refs.box5.getBoundingClientRect().top;
      const box6Top = this.$refs.box6.getBoundingClientRect().top;
      const box7Top = this.$refs.box7.getBoundingClientRect().top;
      const box8Top = this.$refs.box8.getBoundingClientRect().top;
      const box9Top = this.$refs.box9.getBoundingClientRect().top;
      const box10Top = this.$refs.box10.getBoundingClientRect().top;
      const box11Top = this.$refs.box11.getBoundingClientRect().top;
      const box12Top = this.$refs.box12.getBoundingClientRect().top;
      if ((window.innerHeight - box2Top) / window.innerHeight > 0.4) {
        this.box2 = true;
        if ((window.innerHeight - box2Top) / window.innerHeight > 1) {
          this.box21 = true;
        }
        if ((window.innerHeight - box2Top) / window.innerHeight > 1.5) {
          this.box22 = true;
        }
        if ((window.innerHeight - box2Top) / window.innerHeight > 1.8) {
          this.box23 = true;
        }
      }
      if ((window.innerHeight - box3Top) / window.innerHeight > 0.4) {
        this.box3 = true;
        if ((window.innerHeight - box3Top) / window.innerHeight > 1) {
          this.box31 = true;
        }
      }
      if ((window.innerHeight - box4Top) / window.innerHeight > 1) {
        this.box4 = true;
      }
      if ((window.innerHeight - box5Top) / window.innerHeight > 0.4) {
        this.box5 = true;
      }
      if ((window.innerHeight - box6Top) / window.innerHeight > 0.4) {
        this.box6 = true;
      }
      if ((window.innerHeight - box7Top) / window.innerHeight > 0.4) {
        this.box7 = true;
      }
      if ((window.innerHeight - box8Top) / window.innerHeight > 0.4) {
        this.box8 = true;
        if ((window.innerHeight - box8Top) / window.innerHeight > 0.7) {
          this.box81 = true;
        }
      }
      if ((window.innerHeight - box9Top) / window.innerHeight > 1) {
        this.box9 = true;
      }
      if ((window.innerHeight - box10Top) / window.innerHeight > 0.4) {
        this.box10 = true;
      }
      if ((window.innerHeight - box11Top) / window.innerHeight > 0.4) {
        this.box11 = true;
      }
      if ((window.innerHeight - box12Top) / window.innerHeight > 0.4) {
        this.box12 = true;
        if ((window.innerHeight - box12Top) / window.innerHeight > 0.6) {
          this.box121 = true;
        }
      }
    },
    toShopping() {
      this.$router.push("/shopping");
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.isActive {
  color: #3cb59e !important;
}
.title {
  width: 100%;
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  div {
    p {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.25rem;
  }
}
@media only screen and (max-width: 992px) {
  .title {
    position: relative;
    p {
      font-size: 1rem !important;
    }
  }
}
.container {
  font-family: PingFangSC-Medium, PingFang SC;
  height: 100%;
  width: 100%;
  position: relative;
  min-width: 1200px;

  .buy {
    position: absolute;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    line-height: 3.125rem;
    z-index: 999;
    .sdeviceHeader {
      margin: 0 auto;
      width: 62.5vw;
    }
    h3 {
      float: left;
      color: #ffffff;
    }
    a {
      color: #ffffff;
      float: right;
      padding-right: 2.5rem;
    }
    a:hover {
      color: #3cb59e;
    }
    .buybtn {
      color: #fff;
      background-color: #3cb59e;
      border-color: #3cb59e;
      float: right;
      margin-top: 5px;
      display: block;
    }
  }
  .warp {
    width: 62.5vw;
    margin: 0 auto;
  }
  .find_down {
    transform: translate3d(0, 160%, 0);
    opacity: 0;
  }
  .find_up {
    transform: none;
    opacity: 1;
  }
  .content {
    text-align: center;
    h3 {
      transition: all 0.5s;
      font-size: 3.5rem;
      color: #000;
      margin: 2.5rem;
      font-weight: 500;
    }
    .box2 {
      text-align: center;
      li {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          font-size: 2.5rem;
          margin: 1.25rem 0;
        }
        span {
          margin: 0 auto;
          background-color: #999999;
          width: 4.125rem;
          height: 8px;
          border-radius: 9px;
          display: block;
        }
        img {
          width: 40rem;
        }
        div {
          width: 33.3333vw;
          overflow: hidden;
          margin: 0 30px;
          transition: all 1s;
        }
      }
    }
    .box3,
    .box5,
    .box6,
    .box7,
    .box8,
    .box9,
    .box11 {
      height: 67.5rem;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      overflow: hidden;
      p {
        font-size: 27px;
      }
      img {
        display: block;
      }
    }
    .box3 {
      position: relative;
      background-image: url(../../assets/mock/Y02B/3备份.jpg);
      div {
        position: absolute;
        top: 59%;
        left: 36%;
        p {
          width: 110px;
          color: #fff;
          background-color: #3cb59e;
          padding: 8px 25px;
          border-radius: 3.125rem;
        }
      }
    }
    .box4 {
      position: relative;
      p {
        position: absolute;
        top: 32%;
        left: 42%;
      }
    }
    .box5 {
      background-image: url(../../assets/mock/Y02B/6备份.jpg);
    }
    .box6 {
      background-image: url(../../assets/mock/Y02B/7备份.jpg);
    }

    .box8 {
      position: relative;
      background-image: url(../../assets/mock/Y02B/9备份.jpg);
      div {
        p {
          width: 19.5313vw;
          color: #fff;
          font-size: 42px;
          border-radius: 42px;
          position: absolute;
          top: 7.8125vw;
          left: 50%;
          transition: all 1s;
        }
        .p1 {
          position: absolute;
          top: 348px;
          left: 373px;
        }
        .p2 {
          position: absolute;
          border-radius: 84px 42px 42px 0;
          top: 28.6458vw;
          left: 62.2396vw;
        }
        .p3 {
          position: absolute;
          border-radius: 42px 84px 0 42px;
          top: 35.6771vw;
          left: 19.2708vw;
        }
      }
    }
    .box9 {
      position: relative;
      background-image: url(../../assets/mock/Y02B/10备份.jpg);
      div {
        p {
          width: 14.3229vw;
          color: #fff;
          background-color: #3cb59e;
          font-size: 42px;
          border-radius: 42px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 1s;
        }
        .p1 {
          position: absolute;
          top: 34.375rem;
          left: 21.25rem;
          border-radius: 42px 84px 0 42px;
        }
        .p2 {
          position: absolute;
          top: 31.25rem;
          left: 78.125rem;
          border-radius: 84px 42px 42px 0;
        }
        .p3 {
          position: absolute;
          top: 46.875rem;
          left: 74.6875rem;
          border-radius: 84px 42px 42px 0;
        }
      }
    }
    .box10 {
      // height: 80rem;
      position: relative;
      // background-image: url(../../assets/mock/Y02L/10备份\ 5.jpg);
      div {
        display: flex;
        align-items: center;
        ul {
          margin-left: 7.5rem;
        }
        li {
          display: flex;
          align-items: center;
          margin: 3.5rem;
          span {
            margin-left: 0.4375rem;
            font-size: 2.25rem;
          }
        }
      }
      .recharge {
        width: 62.5vw;
        margin: 0 auto;
        margin-top: 1.875rem;
        display: flex;
        justify-content: space-between;
        span {
          font-size: 1.8229vw;
          font-weight: 400;
          color: #000;
          background-color: #d2c490;
          padding: 0.9375rem 2.5625rem;
          border-radius: 2.0313vw;
        }
      }
    }
    .box11 {
      background-image: url(../../assets/mock/Y02B/12备份.jpg);
    }
    .box12 {
      div {
        min-width: 75rem;
        display: flex;
        justify-content: flex-end;
        margin-right: 5.875rem;
        img {
          transition: all 1s;
          opacity: 1;
        }
        ul {
          margin-top: 1.25rem;
          margin-right: 7.8125rem;
          text-align: left;
          transition: all 1s;
          li {
            color: #000;
            p {
              margin: 1.25rem 0;
              font-size: 2.1875rem;
              font-weight: 500;
            }
            span {
              font-size: 1.375rem;
              font-weight: 400;
            }
          }
        }
        .img_up {
          transform: translate3d(16.1458vw, 0, 0);
          opacity: 0;
        }
      }
    }
  }
}
::v-deep .el-radio__input {
  display: none;
}
::v-deep .el-radio__label {
  color: #fff;
}
</style>
